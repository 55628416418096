<template>
  <div class="x-wrap">
    <div class="x-page-title">
      <h3>藏品集管理</h3>
    </div>

    <div class="x-main">
      <!-- 筛选条件 -->
      <div class="x-q-panel">
        <el-row :gutter="20">
          <el-col :span="8">
            <div class="x-con-item">
              <label>查询：</label>
              <el-input
                placeholder="请输入藏品集ID、藏品集名称、品牌"
                v-model="query.content"
              ></el-input>
            </div>
          </el-col>

          <el-col :span="3">
            <el-button
              type="primary"
              class="x-q-btn"
              @click="
                query.pageIndex = 1;
                doQuery();
              "
              >筛选</el-button
            >
            <el-button type="text" @click="doQueryReset">重置</el-button>
          </el-col>
        </el-row>
      </div>

      <!-- 筛选结果 -->
      <div class="x-table-container">
        <div class="x-table-caption">
          <el-button
            icon="el-icon-refresh"
            type="primary"
            plain
            size="small"
            @click="doRefresh"
            >刷新</el-button
          >
          <el-button type="primary" size="small" class="fr" @click="goCreate"
            >添加藏品集</el-button
          >
        </div>

        <div
          class="x-table"
          v-loading="showLoading"
          element-loading-text="数据刷新中..."
        >
          <el-table :data="tableData" stripe style="width: 100%">
            <el-table-column prop="id" label="藏品集ID" width="120">
            </el-table-column>
            <el-table-column
              prop="collectionName"
              label="藏品集名称"
              width="150"
            ></el-table-column>
            <el-table-column prop="brandName" label="品牌" align="center">
            </el-table-column>
            <el-table-column
              prop="nftType"
              label="NFT种类"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="createdAt"
              label="创建时间"
              align="center"
            ></el-table-column>
            <el-table-column prop="updatedAt" label="更新时间">
            </el-table-column>
            <el-table-column
              prop="brandBlockchain"
              label="审核状态"
              align="center"
            >
              <template slot-scope="scope">
                <span class="font-warning" v-if="scope.row.status == 2"
                  >待审核</span
                >
                <span class="font-success" v-else-if="scope.row.status == 3"
                  >审核通过</span
                >
                <span class="font-secondary" v-else-if="scope.row.status == 1"
                  >未上架</span
                >
                <span class="font-danger" v-else-if="scope.row.status == 4"
                  >审核失败</span
                >
                <span class="font-primary" v-else-if="scope.row.status == 5"
                  >待上架</span
                >
                <span class="font-success" v-else-if="scope.row.status == 6"
                  >已上架</span
                >
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="180">
              <template slot-scope="scope">
                <div class="flex-box-around">
                  <el-link
                    :underline="false"
                    type="primary"
                    @click="goUpdate(scope.row.id)"
                    >编辑</el-link
                  >
                  <el-link
                    :underline="false"
                    type="danger"
                    @click="doDelete(scope.row.id)"
                    >删除</el-link
                  >
                </div>
              </template>
            </el-table-column>
          </el-table>

          <div class="x-pager right">
            <el-pagination
              background
              layout="sizes, prev, pager, next"
              :current-page.sync="query.pageIndex"
              :page-size="query.pageSize"
              :page-sizes="[10, 20, 30, 50]"
              :total="total"
              @size-change="setPageSize"
              @current-change="setPageIndex"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
    <!-- 添加臧品集面板 -->
    <collectionPanel
      :dialogshow.sync="dialogshow"
      ref="collectionPanel"
      @updata="doQuery"
    ></collectionPanel>
  </div>
</template>

<script>
import { CollectionQuery, CollectionRemove } from "@/api/module/collection";

export default {
  name: "CollectionIndex",
  data() {
    return {
      showLoading: false,
      dialogshow: false,
      query: {
        content: "",
        pageIndex: 1,
        pageSize: 10,
      },
      total: 0,
      tableData: [],
    };
  },
  components: {
    collectionPanel: () => import("./components/CollectionPanel.vue"),
  },
  created() {
    this.doQuery();
  },
  methods: {
    // 查询
    doQuery() {
      let query = this.setQueryParams();
      CollectionQuery(query).then((r) => {
        this.total = r.total;
        this.tableData = r.list;
      });
    },

    // 设置筛选
    setQueryParams() {
      let query = `?pagination=true&page=${this.query.pageIndex}&pageSize=${this.query.pageSize}`;
      if (this.query.content) {
        query += `&like=${this.query.content}`;
      }

      return query;
    },

    // 重置筛选
    doQueryReset() {
      this.query = {
        content: "",
        pageIndex: 1,
        pageSize: 10,
      };
      this.doQuery();
    },

    // 刷新
    doRefresh() {
      this.showLoading = true;
      this.doQueryReset();
      setTimeout(() => {
        this.$message({
          type: "success",
          message: "数据更新完毕！",
          duration: 1300,
          onClose: () => {
            this.showLoading = false;
          },
        });
      }, 1350);
    },

    // 删除
    doDelete(id) {
      const msg = "<span class='font-danger'>确认要删除本条数据吗？</span>";
      this.$alert(msg, "删除提示", {
        dangerouslyUseHTMLString: true,
        showCancelButton: true,
        confirmButtonText: "确定删除",
        center: true,
        callback: (action) => {
          if (action === "confirm") {
            CollectionRemove(id).then((r) => {
              if (r.code === 0) {
                this.doQuery();
                this.$message({
                  type: "success",
                  message: "删除成功",
                  duration: 1300,
                });
              }
            });
          }
        },
      });
    },

    // 去新增
    goCreate() {
      this.dialogshow = true;
    },

    // 去编辑
    goUpdate(id) {
      this.dialogshow = true;
      this.$refs["collectionPanel"].getDetail(id);
    },

    // 设置分页
    setPageSize(pageSize) {
      this.query.pageIndex = 1;
      this.query.pageSize = pageSize;
      this.doQuery();
    },

    // 设置页码
    setPageIndex(pageIndex) {
      this.query.pageIndex = pageIndex;
      this.doQuery();
    },
  },
};
</script>



